<template>
  <div id="upcoming">
    <UpcomingEvents :career-mode="false"></UpcomingEvents>
    <div>
      <div v-if="admin">
        <v-btn
          :loading="updateEventLoading"
          @click="updateUFCEvents"
        >
          Update UFC Events
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import UpcomingEvents from '@/views/components/UpcomingEvents.vue'

export default {
  components: {
    UpcomingEvents,
  },
  data() {
    return {
      user: {
        isLoggedin: false,
        uid: null,
      },
      admin: false,
      updateEventLoading: false,
    }
  },
  mounted() {
    if (this.$store.state.user) {
      this.user = this.$store.state.user
      this.admin = this.user.profile?.admin
    }
  },
  methods: {
    updateUFCEvents() {
      this.updateEventLoading = true
      console.log('Update UFC events')
      console.log(this.$baseURL)
      const api = axios.create({
        baseURL: this.$baseURL,
        timeout: 10 * 60 * 1000,
      })
      api.get('upcoming').then(response => {
        this.updateEventLoading = false
        console.log(response)
      }).catch(err => {
        console.log(err)
        this.updateEventLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
</style>
