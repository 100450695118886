<template>
  <v-card class="pick-main">
    <div class="d-none d-sm-block">
      <router-link
        to="/past-winnings"
        class="float-right mt-2 ml-1 mr-2 mb"
      >
        <v-btn
          outlined
          small
        >
          Prizes Won
        </v-btn>
      </router-link>
      <v-btn
        v-if="!togglePast"
        outlined
        class="float-right mt-2 ml-1 mr-2"
        small
        @click="showPast"
      >
        Past<span class="hidden-sm-and-down">&nbsp;Events</span>
      </v-btn>
      <v-btn
        v-if="togglePast"
        outlined
        class="float-right mt-2 ml-1 mr-2"
        small
        @click="showUpcoming"
      >
        Upcoming <span class="hidden-sm-and-down">&nbsp;Events</span>
      </v-btn>
    </div>

    <v-card-title>
      <span v-if="!togglePast">Upcoming </span> <span v-if="togglePast">Past </span>
      <span class="hidden-sm-and-down"> &nbsp;Tournaments &</span>&nbsp;Events

      <v-spacer></v-spacer>
      <v-menu
        bottom
        left
        class="float-right"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-sm-none">
            <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </div>
        </template>

        <v-list>
          <v-list-item
            v-if="!togglePast"
            class="event-menu-item"
          >
            <v-list-item-title @click="showPast">
              Past <span class="hidden-sm-and-down">Events</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="togglePast"
            class="event-menu-item"
          >
            <v-list-item-title @click="showUpcoming">
              Upcoming <span class="hidden-sm-and-down">Events</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <router-link
                to="/past-winnings"
              >
                Past Winnings
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-data-table
      id="upcoming-events-table"
      class="mt-1"
      :headers="computedHeaders"
      :items="selectedEvents"
      sort-by="timestamp"
      :sort-desc="sortDesc"
      must-sort
      :loading="tableLoading"
      loading-text="Loading events..."
      :items-per-page="tableRows"
    >
      <template #[`item.name`]="{item, index}">
        <div class="d-flex flex-column">
          <router-link
            :to="item.link"
            :disabled="index<=0"
          >
            <h4 class="d-block font-weight-semibold text-wrap mt-4 fname">
              {{ item.name }}
            </h4>
            <p class="elocation text-xs text--primary">
              {{ item.location|removeNone }}
            </p>
          </router-link>
        </div>

        <div
          v-if="event_type='MMA Fight'"
          itemscope
          itemtype="https://schema.org/BroadcastEvent"
        >
          <span
            itemprop="name"
            :content="item.name"
          ></span>
          <span
            itemprop="description"
            :content="item.name"
          ></span>
          <meta
            itemprop="startDate"
            :content="item.date|moment()"
          >
          <meta
            itemprop="location"
            :content="item.location"
          />
          <link
            itemprop="isLiveBroadcast"
            href="https://schema.org/True"
          />
          <span
            itemprop="startDate"
            :content="item.date|moment()"
          ></span>
          <div
            itemprop="broadcastOfEvent"
            itemscope
            itemtype="https://schema.org/SportsEvent"
          >
            <span
              itemprop="name"
              :content="item.name"
            ></span>
            <span
              itemprop="organizer"
              content="UFC"
            ></span>
            <span
              itemprop="organizer.url"
              content="https://www.ufc.com"
            ></span>
            <span
              itemprop="sport"
              content="MMA"
            ></span>
            <span
              itemprop="contributor"
              content="FantasyKombat.com"
            ></span>
            <span
              itemprop="sponsor"
              content="FantasyKombat.com"
            ></span>
            <span
              itemprop="url"
              :content="`https://www.fantasyKombat.com/${item.link}`"
            ></span>
            <span
              itemprop="description"
              :content="item.name"
            ></span>
            <meta
              itemprop="location"
              :content="item.location"
            />
            <meta
              itemprop="startDate"
              :content="item.date|moment()"
            >
            <div
              itemprop="competitor"
              itemscope
              itemtype="https://schema.org/SportsTeam"
            >
              <span
                itemprop="name"
                :content="item.name"
              ></span>
              <meta
                itemprop="location"
                :content="item.location"
              />
              <meta
                itemprop="startDate"
                :content="item.date|moment()"
              >
            </div>
          </div>
        </div>
      </template>

      <template #[`item.timestamp`]="{item}">
        <div
          v-if="item.date"
          class="d-flex flex-column"
        >
          <span class="d-block font-weight-semibold text--primary text-truncate">{{
              item.date|moment("dddd MMM Do")
            }}</span>
          <span class="d-block font-weight-semibold text--primary text-truncate">{{
              item.date|moment("h:mm a")
            }} <small>{{ tz }}</small></span>
        </div>
        <div
          v-else
          class="d-flex flex-column"
        >
          <span class="d-block font-weight-semibold text--primary text-truncate">{{
              item.rawdate|moment("dddd MMM Do")
            }}</span>
          <span class="d-block text-truncate">{{
              item.rawdate|moment("h:mm a")
            }} <small>{{ tz }}</small></span>
        </div>
      </template>

      <template #[`item.prize_pool`]="{item}">
        <div class="hidden-sm-and-up">
          <p></p>
          <span class="font-weight-semibold text--primary hidden-sm-and-up">Prize Pool</span>
        </div>
        <v-avatar
          size="25"
          class="mr-0"
        >
          <v-img
            src="@/assets/images/buy/KombatCoinSilver.png"
            class="float-left mt-0"
          ></v-img>
        </v-avatar>
        <span class="font-weight-semibold ml-1 text--white text--primary ">{{
            item.prize_pool|toCurrencyNoDec
          }}</span>
      </template>
      <!-- status -->
      <template #[`item.status`]="{item}">
        <router-link
          :to="item.link"
          class="text-center mx-auto d-block"
        >
          <div>
            <v-chip
              v-if="item.status == 'Completed'"
              small
              outlined
              :color="statusColor[item.status]"
            >
              {{ item.status }}
            </v-chip>

            <v-chip
              v-else-if="item.status == 'Pending'"
              small
              outlined
              :color="statusColor[item.status]"
            >
              {{ item.status }}
            </v-chip>

            <v-chip
              v-else-if="item.status == 'Live'"
              small
              outlined
              :color="statusColor[status['live']]"
            >
              Live
            </v-chip>
            <v-chip
              v-else-if="item.status == 'Final' || item.status == 'Over'"
              small
              outlined
              color="warning"
            >
              Processing Points
            </v-chip>
            <v-chip
              v-else-if="item.status == 'Completed' || item.status == 'FKCompleted'"
              small
              outlined
              :color="statusColor[item.status]"
            >
              Completed
            </v-chip>
            <v-chip
              v-else-if="selectedEvents.indexOf(item)==0"
              small
              outlined
              :color="statusColor[status['next']]"
              class="hidden-sm-and-down"
            >
              {{ status['next'] }}
            </v-chip>

            <v-chip
              v-else-if="!item.eventId"
              small
              outlined
              :color="statusColor[status[item.status]]"
            >
              Upcoming
            </v-chip>
            <v-chip
              v-else
              small
              outlined
              :color="statusColor[status[item.status]]"
            >
              {{ item.status }}
            </v-chip>
          </div>
          <div v-if="selectedEvents.indexOf(item)<=0 && item.pickCount">
            <v-chip
              small
              outlined
              class="mt-1"
              :class="{'success': item.pickCount === item.fightCount && item.fightCount > 3, 'primary': item.pickCount > 0}"
            >
              Picks: <span v-if="item.pickCount >item.fightCount ">{{ item.fightCount }}</span>
              <span v-else>{{ item.pickCount }}</span>/{{ item.fightCount }}
            </v-chip>
          </div>
        </router-link>
      </template>
      <!-- action -->
      <template #[`item.action`]="{item}">
        <router-link
          v-if="selectedEvents.indexOf(item)<=1 || togglePast"
          :to="item.link"
        >
          <v-btn
            :disabled="selectedEvents.indexOf(item)>=2 && !togglePast"
            small
            outlined
            color="success"
            class="d-block mx-auto elevation-6"
          >
            View
          </v-btn>

          <v-chip
            v-if="isMobile &&selectedEvents.indexOf(item)<=0"
            small
            outlined
            :class="{'success': item.pickCount >= item.fightCount && item.fightCount > 3, 'primary': item.pickCount > 0}"
          >
            Picks: {{ item.pickCount }}/{{ item.fightCount }}
          </v-chip>
        </router-link>
        <div v-else>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                small
                outlined
                class="d-block mx-auto opacity-1"
                v-on="on"
              >
                View
              </v-btn>
            </template>
            <span> Tournaments open 1 week before the fight date</span>
          </v-tooltip>
        </div>

        <v-row
          v-if="isMobile"
          class="mt-0 pt-0"
        >
          <div
            v-for="fights in item.fights"
            :key="fights.eventId"
          >
            <v-col
              cols="6"
              class="float-left mt-0 pt-0"
            >
              <v-img
                v-if="fights.order === 1"
                class="f1e"
                :src="fighterImagesURL+'fighters/images/body-'+fights.fighter_1_slug+'_248x380.png'"
              >
              </v-img>
            </v-col>
            <v-col
              cols="6"
              class="float-right mt-0 pt-0"
            >
              <v-img
                v-if="fights.order === 1"
                class="f2e"
                :src="fighterImagesURL+'fighters/images/body-'+fights.fighter_2_slug+'_248x380.png'"
              >
              </v-img>
            </v-col>
          </div>
        </v-row>
      </template>
      <template v-slot:[`footer.page-text`]="">
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mdiSquareEditOutline, mdiDotsVertical, mdiTrophy} from '@mdi/js'
import dbFunctions from '@/db'

export default {
  name: 'UpcomingEventsComp',
  metaInfo() {
    return {
      title: 'Upcoming UFC / MMA events',
    }
  },
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      View: 'success',
      Next: 'primary',
      Upcoming: '',
      Live: 'error',
      Final: 'success',
      Completed: 'success',
      FKCompleted: 'success',
      Cancelled: 'warning',
      /* eslint-enable key-spacing */
    }

    return {
      status: {
        upcoming: 'Upcoming',
        live: 'Live',
        next: 'Next',
        pending: 'Results Pending',
        past: 'Completed',
        cancelled: 'Cancelled',
      },
      statusColor,
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiTrophy,
      },
    }
  },
  filters: {
    removeNone(value) {
      if (!value) return ''

      return value.replace('None,', '')
    },
  },
  props: {
    passTableRows: Number,
  },
  data() {
    return {
      isMobile: false,
      tableRows: 5,
      headers: [
        {text: 'Event', value: 'name'},
        {text: 'Date', value: 'timestamp'},
        {text: 'Prize Pool', value: 'prize_pool'},
        {text: 'Status', value: 'status', hide: 'smAndDown'},
        {text: 'Action', value: 'action'},
      ],
      user: this.$store.state.user,
      eventList: [],
      selectedEvents: [],
      sortDesc: false,
      togglePast: false,
      tableLoading: true,
      myPicks: [],
      totalPoints: 0,
      powerUpPoints: 0,
      grandTotalPoints: 0,
    }
  },
  computed: {
    computedHeaders() {
      return this.headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },
    pastEvents() {
      const pastEvents = this.eventList.filter(item => item.start_date <= new Date())

      return pastEvents
    },
    upcomingEvents() {
      const events = this.eventList

      return events
    },
  },
  mounted() {
    if (this.passTableRows) {
      this.tableRows = this.passTableRows
    }
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
    this.showUpcoming()
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
      if (this.isMobile) {
        this.tableRows = 1
      }
    },
    async showPast() {
      this.sortDesc = true
      this.tableLoading = true
      this.togglePast = true
      this.selectedEvents = this.pastEvents
      const past = true
      await dbFunctions.allUFCEvents(past)
        .then(events => {
          this.selectedEvents = events
          this.tableLoading = false
        })
    },
    showUpcoming() {
      this.sortDesc = false
      this.tableLoading = true
      this.togglePast = false
      const past = false
      dbFunctions.allUFCEvents(past)
        .then(MMAevents => {
          this.eventList = MMAevents
          this.tableLoading = false
          for (let i = 0; i < this.eventList.length; i += 1) {
            if (Object.entries(this.eventList[i].fights)) {
              this.eventList[i].fightCount = Object.entries(this.eventList[i].fights).filter(e => e[1].fight_status !== 'Cancelled').length
              if (this.eventList[i].id && this.user?.uid) {
                this.getPicks(this.eventList[i].id, i)
              }
            }
          }
          this.selectedEvents = this.upcomingEvents
        })
    },
    getPicks(eventId, idx) {
      let myPicks = 0
      dbFunctions.getPicks(this.user.uid, this.$moment(eventId, 'YYYY-MM-DD').format('MMMM-DD-YYYY'))
        .then(eventData => {
          if (eventData?.data) {
            for (let i = 0; i < eventData?.data.length; i += 1) {
              if (this.eventList[idx].fights[eventData?.data[i].ufc_fight_id]) {
                if (this.eventList[idx].fights[eventData?.data[i].ufc_fight_id].fight_status !== 'Cancelled') {
                  myPicks += 1
                }
              }
            }
            this.$set(this.eventList[idx], 'pickCount', myPicks)
          } else {
            this.$set(this.eventList[idx], 'pickCount', myPicks)
          }
        })
    },
  },
}
</script>

<style>

h4.d-block.font-weight-semibold.text-truncate.mt-4.fname {
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serf, serif;
}

#upcoming-events-table tr.v-data-table__mobile-table-row {
  background: url('/img/purplecomb.webp') no-repeat;
  background-size: cover;
  margin: 0;
  text-shadow: 1px 2px 2px #000;
}

.event-menu-item {
  cursor: pointer !important;
  color: #ffffff !important;
}

#upcoming-events-table thead.v-data-table-header.v-data-table-header-mobile {
  display: none;
}

#upcoming-events-table .v-data-table__mobile-row__header {
  display: none;
  padding: 10px 0;
}

#upcoming-events-table .v-data-table__mobile-row__cell {
  text-align: center;
  margin: auto;
  font-size: 1.2em;
  line-height: 28px;
}

#upcoming-events-table .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  margin: 8px 0px 8px 0px;
}

#upcoming-events-table .v-data-table__mobile-row__cell .v-btn {
  margin: 28px 0 28px 0;
  font-size: 14px;
  padding: 0 23.1111111111px;
  height: 40px !important;
}

#upcoming-events-table tr.v-data-table__mobile-table-row {
  padding-top: 18px;
}

#upcoming-events-table .v-data-table--mobile .v-data-footer__select {
  display: none;
}

.v-data-footer__select {
  display: none;
}

.f1e {
  transform: scaleX(-1);
  position: absolute;
  left: 2%;
  width: 34%;
  bottom: 60px;
}

.f2e {
  position: absolute;
  right: 2%;
  width: 34%;
  bottom: 60px;
}

</style>
